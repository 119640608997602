'use strict';

import './payment.link.modal.less';
import {
  cleanObj,
  getSafely,
  isConcreteValue,
  isNonEmptyObject,
  isNonEmptyString, isNumber
} from "@tomeravni/easybizy-js-common/common";
import {extractError} from "@tomeravni/easybizy-js-common/errors";

import Moment from "moment";
import {calculateNextOccurrence} from "@tomeravni/easybizy-js-common/date";

angular.module('easybizy.easybizyModalViewsModel').controller('PaymentLinkController',
  function (whatsapp, $scope, $modalInstance, sessionId, hasRelatedMeetings, customer, intermediateCartId, configuration, pci, localize, Repository, onCreateDelegate) {
    $scope.cancel = function () {
      $modalInstance.dismiss();
    };

    $scope.adaptedInvoiceResult = {
      items: [],
      Customer: {
        MobileFirst: customer.mobile,
      }
    }

    const configurations = configuration.get();

    $scope.includeAddress = configurations.InvoiceSettings.IncludeCustomerAddressOnInvoice;
    $scope.includeIdentifyNumber = configurations.InvoiceSettings.IncludeCustomerIdNumberOnInvoice;

    const paymentLinkSettings = configurations.PaymentLinkSettings || {};

    let notifyBusinessConfigurations = getSafely(['MobileNumbersToNotifyPaymentLinkPaid', 'Value'], paymentLinkSettings);
    let expirationOldValue = getSafely(['DefaultExpirationOfPaymentLink', 'Value'], paymentLinkSettings);
    let expirationValue = getSafely(['DefaultExpirationValueOfPaymentLink', 'Value'], paymentLinkSettings) ?? expirationOldValue;
    let snoozeMessageFromConfig = getSafely(['ReminderMessageContentAboutPaymentLink', 'Value'], paymentLinkSettings);
    let snoozingDaysIntervalFromConfig = getSafely(['RemindCustomersAboutPaymentLink', 'Value'], paymentLinkSettings);
    let paymentLinkSMSMessage = getSafely(['CashRegisterSettings', 'PaymentLinkSMSMessage', 'Value'], configurations);
    let supportsGiftCard = paymentLinkSettings.SupportsGiftCardOnPaymentLink;
    let showNonPaidItems = !paymentLinkSettings.HideNonPaidItems;
    let defaultMaxAmountOfPayments = getSafely(['CashRegisterSettings', 'MaximumNumberOfPayments', 'Value'], configurations);

    $scope.orderConfirmationEnabled = hasRelatedMeetings;

    const removeRelatedMeetingsUponExpiration = hasRelatedMeetings && !!paymentLinkSettings.RemoveRelatedMeetingsUponExpiration;
    const sendOrderConfirmationUponPayment = hasRelatedMeetings && paymentLinkSettings.SendOrderConfirmationUponPayment;
    const orderConfirmationMessage = getSafely(['PaymentLinkOrderConfirmationMessage', 'Value'], paymentLinkSettings) || localize.getLocalizedString('_MeetingApproved_');

    const whatsappConfig = getSafely(['loggedInUser', 'whatsappProps'], configuration, {});
    $scope.sendPaymentLinkUsingWhatsapp = configuration.loggedInUser?.isWhatsappSupported && whatsappConfig?.sendPaymentLinkViaWhatsapp;
    $scope.sendMainButton = localize.getLocalizedString(!$scope.sendPaymentLinkUsingWhatsapp ? '_SendBySms_' : '_SendByWhatsapp_');

    let notifyOwnerTo = {
      to: notifyBusinessConfigurations ? notifyBusinessConfigurations.split(',').map((x) => x.trim()) : [],
      method: 'sms'
    }

    if (defaultMaxAmountOfPayments > 1) {
      $scope.installmentValues = Array.from({length: defaultMaxAmountOfPayments - 1}, (v, k) => ({
        name: localize.getLocalizedString('_NumOfPayments_', k + 1),
        value: k + 1
      }));

      $scope.installmentValues[0].name = localize.getLocalizedString('_NoPayments_');
      $scope.maxNumberOfInstallments = {selected: $scope.installmentValues[0]}
    }

    const snoozeMessage = isNonEmptyString(snoozeMessageFromConfig) ? snoozeMessageFromConfig : localize.getLocalizedString('_SnoozePaymentLinkMessage_')

    $scope.paymentLink = {
      title: localize.getLocalizedString('_PaymentRequest_'),
      invoiceRemarks: configurations.CashRegisterSettings.InvoiceRemarksTemplate?.Value,
      customerName: customer.customerName,
      mobile: customer.mobile,
      address: customer.address,
      supportsGiftCard: supportsGiftCard,
      identifyNumber: customer.identifyNumber,
      showNonPaidItems,
      sendOrderConfirmationUponPayment,
      removeRelatedMeetingsUponExpiration: removeRelatedMeetingsUponExpiration
    };

    const notifyCustomerOfPaymentMessage = paymentLinkSMSMessage || localize.getLocalizedString('_DefaultPaymentLinkCustomerMessageText_');

    const fieldsNameMap = {
      title: localize.getLocalizedString('_PriceQuoteTitle_'),
      customerName: localize.getLocalizedString('_CustomerName_'),
      mobile: localize.getLocalizedString('_MobileFirst_'),
    };

    $scope.recurrenceSnoozeValues = [
      {
        name: localize.getLocalizedString('_None_'),
        value: null,
        interval: 0,
      },
      {
        name: localize.getLocalizedString('_Daily_'),
        value: 'FREQ=DAILY',
        interval: 1
      },
      {
        name: localize.getLocalizedString('_Every3Days_'),
        value: 'FREQ=DAILY;INTERVAL=3',
        interval: 3
      },
      {
        name: localize.getLocalizedString('_ByWeekly_'),
        value: 'FREQ=WEEKLY',
        interval: 7
      },
      {
        name: localize.getLocalizedString('_Weekly_'),
        value: 'FREQ=WEEKLY;INTERVAL=2',
        interval: 14
      },
      {
        name: localize.getLocalizedString('_Monthly_'),
        value: 'FREQ=MONTHLY',
        interval: 30
      }
    ];

    $scope.snoozeWrapper = {
      selected: $scope.recurrenceSnoozeValues.filter((x) => {
        return x.interval?.toString() === snoozingDaysIntervalFromConfig
      })[0]
    };

    $scope.savingWrapper = {
      saving: false
    };

    $scope.expirationOptions = [
      {
        name: `${localize.getLocalizedString('_QHour_')}`,
        value: 'PT15M'
      },
      {
        name: `${localize.getLocalizedString('_HalfAnHour_')}`,
        value: 'PT30M'
      },
      {
        name: `45 ${localize.getLocalizedString('_Minutes_')}`,
        value: 'PT45M'
      },
      {
        name: `${localize.getLocalizedString('_Hour_')}`,
        value: 'PT1H'
      },
      {
        name: `2 ${localize.getLocalizedString('_Hours_')}`,
        value: 'PT2H'
      },
      {
        name: `3 ${localize.getLocalizedString('_Hours_')}`,
        value: 'PT3H'
      },
      {
        name: `5 ${localize.getLocalizedString('_Hours_')}`,
        value: 'PT5H'
      },
      {
        name: `24 ${localize.getLocalizedString('_Hours_')}`,
        value: 'PT24H'
      },
      {
        name: `2 ${localize.getLocalizedString('_Days_')}`,
        value: 'P2D'
      },
      {
        name: `3 ${localize.getLocalizedString('_Days_')}`,
        value: 'P3D'
      },
      {
        name: `${localize.getLocalizedString('_Week_')}`,
        value: 'P1W'
      },
      {
        name: `2 ${localize.getLocalizedString('_Weeks_')}`,
        value: 'P2W'
      },
      {
        name: `3 ${localize.getLocalizedString('_Weeks_')}`,
        value: 'P3W',
      },
      {
        name: localize.getLocalizedString('_Month_'),
        value: 'P1M',
      },
      {
        name: localize.getLocalizedString('_2Months_'),
        value: 'P2M',
      },
      {
        name: localize.getLocalizedString('_3Months_'),
        value: 'P3M',
      },
      {
        name: localize.getLocalizedString('_6Months_'),
        value: 'P6M'
      },
    ];


    let selectedExpirationValue = $scope.expirationOptions[$scope.expirationOptions.length - 1].value;
    if (expirationValue && expirationValue.startsWith('P')) {
      selectedExpirationValue = expirationValue;
    }

    $scope.expirationWrapper = {
      selected: $scope.expirationOptions.filter((x) => {
        return x.value === selectedExpirationValue
      })[0] || $scope.expirationOptions[$scope.expirationOptions.length - 1]
    }

    /*EmployeeId*/

    $scope.sendPaymentLink = (isWhatsapp = false) => {
      const someInvalid = Object.keys($scope.paymentLink).some((field) => {
        if (!isConcreteValue($scope.paymentLink[field]) && fieldsNameMap[field]) {
          toastr.error(`${fieldsNameMap[field]} - ` + localize.getLocalizedString('_ThisFieldIsRequired_'))
          return true;
        }

        return false;
      })

      if (someInvalid) {
        return;
      }

      $scope.savingWrapper.saving = true;
      let snooze = getSafely(['snoozeWrapper', 'selected', 'value'], $scope);
      if (isConcreteValue(snooze)) {
        snooze = {
          to: $scope.paymentLink.mobile.split(',').map((phone) => phone.trim()),
          message: {
            he_IL: snoozeMessage,
            en_US: snoozeMessage
          },
          recurrence: snooze,
          method: 'sms'
        }
      }

      const phoneNumbers = $scope.paymentLink.mobile.split(',').map((phone) => phone.trim());
      let invoiceDetails = cleanObj({
        address: $scope.paymentLink.address,
        identifyNumber: $scope.paymentLink.identifyNumber,
      });


      const toSend = {
        snooze,
        paymentType: 'payment',
        supportsGiftCard: !!$scope.paymentLink.supportsGiftCard,
        showNonPaidItems: !!$scope.paymentLink.showNonPaidItems,
        notifyCustomer: isWhatsapp ? null : {
          message: {
            he_IL: notifyCustomerOfPaymentMessage,
            en_US: notifyCustomerOfPaymentMessage
          },
          to: phoneNumbers,
          method: 'sms'
        },
        customerName: $scope.paymentLink.customerName,
        sendInvoiceTo: {
          to: phoneNumbers,
          method: 'sms',
          optionalWhatsapp: $scope.sendPaymentLinkUsingWhatsapp
        },
        notifyOwnerTo,
        descriptionText: {
          he_IL: $scope.paymentLink.title,
          en_US: $scope.paymentLink.title
        },
        employeeId: configuration.defaultEmployee().id,
        invoiceDetails: isNonEmptyObject(invoiceDetails) ? invoiceDetails : invoiceDetails,
        intermediateCartId
      };

      const duration = moment.duration($scope.expirationWrapper.selected.value);
      const expirationDate = moment().add(duration);
      toSend.expiresOn = expirationDate.add(2, 'minute').toDate();

      if (isNonEmptyObject(snooze)) {
        try {
          const nextSnoozeOccurrence = calculateNextOccurrence(new Date(), snooze.recurrence);
          if (nextSnoozeOccurrence > toSend.expiresOn) {
            toSend.snooze = null;
          }
        } catch (e) {
          $scope.savingWrapper.saving = false;
          toastr.error(extractError(e));
          return;
        }


      }

      if (isNonEmptyString($scope.paymentLink.invoiceRemarks)) {
        toSend.invoiceRemarks = {
          he_IL: $scope.paymentLink.invoiceRemarks,
          en_US: $scope.paymentLink.invoiceRemarks
        };
      }

      let maxNumOfInstallments = getSafely(['maxNumberOfInstallments', 'selected', 'value'], $scope)
      if (maxNumOfInstallments > 1) {
        toSend.maxNumOfInstallments = maxNumOfInstallments;
      }

      if ($scope.paymentLink.removeRelatedMeetingsUponExpiration) {
        toSend.removeRelatedMeetingsUponExpiration = true;
      }

      if ($scope.orderConfirmationEnabled && $scope.paymentLink.sendOrderConfirmationUponPayment) {
        toSend.orderConfirmationMessage = orderConfirmationMessage;
      }

      Repository.Custom('Sessions').createOrUpdatePaymentLink(sessionId, cleanObj(toSend))
        .then((res) => {
          onCreateDelegate(res);
          toastr.success(localize.getLocalizedString('_MessageSent_'));
          if (isWhatsapp) {
            whatsapp.open(phoneNumbers[0], `${notifyCustomerOfPaymentMessage} ${res.paymentLink.shortUrl}`);
          }

          $scope.cancel();
        })
        .catch((err) => {
          $scope.savingWrapper.saving = false;
          toastr.error(extractError(err.data));
        })
    }


  });
