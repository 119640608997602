import {
  COLUMN_EDITOR_TYPE, GRID_FILTER_TYPE,
  GridApiResult,
  GridColumn,
  LazyGridDataSource,
  DatePickerLabels,
  SortByColumn
} from "@tomeravni/easybizy-vue-components";

import Moment from 'moment';

import {
  isNonEmptyArray,
  isNonEmptyString,
  isNonEmptyObject,
  performIfDelegate, valueOrDefault, setIfHasValue, isFunction
} from "@tomeravni/easybizy-js-common/common";
import {
  ODATA_DATE_TIME_FORMAT,
  SERVER_DATA_DATE_FORMAT,
  DATE_DISPLAY_FORMAT,
  RTL_DATE_TIME_DISPLAY
} from "../../constants";
import { DATE_RANGE_FIELDS, MULTI_SELECTION_FIELDS } from "./leads.constants";
import { extractError } from "@tomeravni/easybizy-js-common/errors";
import { ClickToCall } from "../../vue/components/common/click-to-call";
import { GridDateColumnFilterAdapter } from "./query.and.filter.adapters";


export class LeadsDS extends LazyGridDataSource {
  constructor(batchSize, api, localize, stateManager, mediator, Repository, printerMediator, $state, toaster) {
    super(batchSize);
    this._mediator = mediator;
    this._api = api;
    this._toaster = toaster;
    this._localize = localize;
    this._stateManager = stateManager;
    this._$state = $state;
    const self = this;

    mediator.indexOfItem = (item) => {
      return this.indexOfItem(item);
    }

    const leadName = new GridColumn('LeadName', localize('_FullName_'), COLUMN_EDITOR_TYPE.LAZY, true);
    leadName.width = 180;
    leadName.filterType = GRID_FILTER_TYPE.STRING;
    leadName.filterArgs = { label: localize('Search'), debounce: true };
    leadName.freeze = true;

    const mobileFirst = new GridColumn('MobileFirst', localize('_MobileFirst_'), COLUMN_EDITOR_TYPE.LAZY);
    mobileFirst.width = 130;
    mobileFirst.filterType = GRID_FILTER_TYPE.STRING;
    mobileFirst.filterArgs = { label: localize('Search'), debounce: true };
    mobileFirst.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h(ClickToCall, { props: { phone: entity.MobileFirst } })
    };

    const status = new GridColumn('LeadStatus', localize('_Status_'), COLUMN_EDITOR_TYPE.SINGLE_SELECT, true);
    status.width = 150;

    const leadStatuses = [{ text: localize("_New_"), value: 'New' },
    { text: localize("_Active_"), value: 'Active' },
    { text: localize("_Suspended_"), value: 'Suspended' },
    { text: localize("_Inactive_"), value: 'Inactive' },
    { text: localize("_BecomeACustomer_"), value: 'BecomeACustomer' }
    ];
    status.filterType = GRID_FILTER_TYPE.MULTI_SELECT;
    status.options = leadStatuses;
    status.filterArgs = {
      placeholder: localize('_LeadsState_'),
      options: leadStatuses.map((status) => ({
        text: status.text,
        value: status.value,
        urlValue: `LeadStatus eq \'${status.value}\'`
      }))
    };


    const subStatus = new GridColumn('LeadSubStatusId', localize('_LeadSubStatus_'), COLUMN_EDITOR_TYPE.SINGLE_SELECT, true);
    subStatus.width = 175;
    subStatus.filterType = GRID_FILTER_TYPE.MULTI_SELECT;
    const delegates = []
    delegates.push(Repository.Custom("EntitiesLazyRepository").leadSubStatus().get().then((res) => {
      const subStatusOptions = res.map((t) => ({
        text: t.name,
        value: t.value
      }))
      subStatus.options = [...subStatusOptions];
      subStatus.filterArgs = {
        placeholder: localize('_LeadSubStatus_'),
        options: subStatusOptions.map((t) => ({
          text: t.text,
          value: t.value,
          urlValue: `LeadSubStatusId eq ${t.value}`
        }))
      };
    }));


    
    const category = new GridColumn('LeadCategoryId', localize('_LeadCategory_'), COLUMN_EDITOR_TYPE.SINGLE_SELECT, true);
    category.width = 175;
    category.isVisible = false;
    category.filterType = GRID_FILTER_TYPE.MULTI_SELECT;
    delegates.push(Repository.Custom("EntitiesLazyRepository").leadCategories().get().then((res) => {
      const categoryOptions = res.map((t) => ({
        text: t.name,
        value: t.value
      }))
      category.options = [...categoryOptions];
      category.filterArgs = {
        placeholder: localize('_LeadCategories_'),
        options: categoryOptions.map((t) => ({
          text: t.text,
          value: t.value,
          urlValue: `LeadCategoryId eq ${t.value}`
        }))
      };
    }));

    const employee = new GridColumn('EmployeeId', localize('_Employee_'), COLUMN_EDITOR_TYPE.SINGLE_SELECT, true);
    employee.width = 175;
    employee.filterType = GRID_FILTER_TYPE.MULTI_SELECT;
    delegates.push(Repository.Entity("Employee").query().get().then(({ value }) => {
      const employees = value.map((employee) => ({
        text: employee.FirstName + " " + employee.LastName,
        value: employee.EmployeeId
      }));

      employee.options = [...employees];
      employee.filterArgs = {
        placeholder: localize('_Employee_'),
        options: employees.map((employee) => ({
          text: employee.text,
          value: `EmployeeId eq ${employee.value}`,
          urlValue: `EmployeeId eq ${employee.value}`
        }))
      };
    }));


    const lastAppeal = new GridColumn('LastAppeal', localize('_AppealDate_'), COLUMN_EDITOR_TYPE.LAZY, true);
    lastAppeal.width = 180;
    lastAppeal.filterType = GRID_FILTER_TYPE.DATE_RANGE;
    lastAppeal.filterArgs = { datePickerLabels: new DefaultDatePickerLabels(localize), value: [new Date()], allowSingleDateOnRangeSelection: true };
    lastAppeal.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h('p', entity.LastAppeal ? Moment(entity.LastAppeal, SERVER_DATA_DATE_FORMAT).format(RTL_DATE_TIME_DISPLAY) : "  ");
    };

    const lastUpdated = new GridColumn('LastUpdated', localize('_Updated_'), COLUMN_EDITOR_TYPE.LAZY, true);
    lastUpdated.width = 150;
    lastUpdated.filterType = GRID_FILTER_TYPE.DATE_RANGE;
    lastUpdated.filterArgs = { datePickerLabels: new DefaultDatePickerLabels(localize), value: [new Date()], allowSingleDateOnRangeSelection: true };
    lastUpdated.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h('p', entity.LastUpdated ? Moment(entity.LastUpdated, SERVER_DATA_DATE_FORMAT).format(RTL_DATE_TIME_DISPLAY) : "  ");
    };

    const arrivalSource = new GridColumn('ArrivalSourceId', localize('_ArrivalSource_'), COLUMN_EDITOR_TYPE.SINGLE_SELECT);
    arrivalSource.width = 150;
    arrivalSource.filterType = GRID_FILTER_TYPE.MULTI_SELECT;


    delegates.push(Repository.Entity("ArrivalSource").query().get().then(({ value }) => {
      const sources = value.map((arrivalSource) => ({
        text: arrivalSource.ArrivalSourceName,
        value: arrivalSource.ArrivalSourceId
      }));

      arrivalSource.options = [...sources];
      arrivalSource.filterArgs = {
        placeholder: localize('_ArrivalSource_'),
        options: sources.map((source) => ({
          text: source.text,
          value: source.value,
          urlValue: `ArrivalSourceId eq ${source.value}`
        }))
      };
    }));

    const tags = new GridColumn('Tags', localize('_Tags_'), COLUMN_EDITOR_TYPE.LAZY);
    tags.width = 250;
    tags.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h('p', entity.Tags && entity.Tags.length > 0 ? entity.Tags.map(o => o.Name).join(', ') : "");
    };
    tags.filterType = GRID_FILTER_TYPE.MULTI_SELECT;

    delegates.push(Repository.Custom("EntitiesLazyRepository").tags("Lead").get().then((tagOptions) => {
      tags.options = [...tagOptions];
      tags.filterArgs = {
        placeholder: localize('_Tags_'),
        options: tagOptions.map((t) => ({
          text: t.name,
          value: t.value,
          urlValue: `TagId eq ${t.value}`
        }))
      };
    }));

    const lastHandlingNote = new GridColumn('LastHandlingNote', localize('_LastHandlingNote_'), COLUMN_EDITOR_TYPE.LAZY);
    lastHandlingNote.width = 350;
    lastHandlingNote.customRenderFunc = (entity, changeDelegate, column, h) => {
      const customTimelineItem = valueOrDefault(entity.CustomTimelineItems, []).slice().pop()
      setIfHasValue(customTimelineItem, 'Id', customTimelineItem?.CustomTimelineItemId)
      let content = customTimelineItem?.Content
      if (isNonEmptyString(content)) {

        return h('div', { class: 'custom-entity-wrapper' }, [
          h('div', { class: 'flex' }, [
            h('button', {
              class: 'text--primary',
              on: {
                click: () => {
                  mediator.addCustomTimelineItem(entity);
                }
              }
            }, localize('_Add_')),
            h('div', { class: 'px-1' }, '|'),
            h('button', {
              class: 'text--primary',
              on: {
                click: () => {
                  mediator.addCustomTimelineItem(entity, () => {
                  }, customTimelineItem);
                }
              }
            }, localize('_Edit_'))
          ]),
          h('div', { domProps: { innerHTML: content } })
        ]);
      } else {
        const addText = localize('_Add_')
        return h('button', {
          class: 'text--primary',
          on: {
            click: () => {
              mediator.addCustomTimelineItem(entity);
            }
          }
        }, addText);
      }

    };

    //todo: find a way to change default labels for date picker
    const nextHandlingTime = new GridColumn('NextHandlingTime', localize('_NextHandlingTime_'), COLUMN_EDITOR_TYPE.DATE, true);
    nextHandlingTime.width = 220;
    nextHandlingTime.filterType = GRID_FILTER_TYPE.DATE_RANGE;
    nextHandlingTime.filterArgs = { datePickerLabels: new DefaultDatePickerLabels(localize), value: [new Date()], allowSingleDateOnRangeSelection: true };
    nextHandlingTime.customAttributes = {
      dateFormat: SERVER_DATA_DATE_FORMAT,
      pickTime: true
    }
    nextHandlingTime.filterAdapter = new GridDateColumnFilterAdapter();

    let totalSpent;
    if (mediator.showIncomes) {
      totalSpent = new GridColumn('TotalIncomesSinceLastContact', localize('_TotalIncomes_'), false, true);
      totalSpent.width = 100;
      totalSpent.isVisible = false;
    }

    const birthday = new GridColumn('DateOfBirth', localize('_DateOfBirth_'), COLUMN_EDITOR_TYPE.LAZY);
    birthday.width = 150;
    birthday.isVisible = false;

    const emailAddress = new GridColumn('EmailAddress', localize('_Email_'), false, true);
    emailAddress.width = 150;
    emailAddress.isVisible = false;

    const remarks = new GridColumn('Remarks', localize('_Remarks_'), COLUMN_EDITOR_TYPE.LAZY);
    remarks.width = 200;
    remarks.isVisible = false;

    const nextMeeting = new GridColumn('NextMeeting', localize('_NextMeeting_'), COLUMN_EDITOR_TYPE.LAZY);
    nextMeeting.width = 150;
    nextMeeting.filterType = GRID_FILTER_TYPE.DATE_RANGE;
    nextMeeting.filterArgs = { datePickerLabels: new DefaultDatePickerLabels(localize), value: [new Date()], allowSingleDateOnRangeSelection: true };
    nextMeeting.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h('p', entity.NextMeeting ? Moment(entity.NextMeeting, SERVER_DATA_DATE_FORMAT).format(DATE_DISPLAY_FORMAT) : "");
    };
    nextMeeting.isVisible = false;

    const nextMeetingExist = new GridColumn('NextMeetingExist', localize('_NextMeetingExist_'), COLUMN_EDITOR_TYPE.LAZY);
    nextMeetingExist.width = 100;
    nextMeetingExist.customRenderFunc = (entity, changeDelegate, column, h) => {
      return h('p', entity.NextMeeting ? localize('_Yes_') : localize('_No_'));
    };
    nextMeetingExist.filterType = GRID_FILTER_TYPE.SINGLE_SELECT;
    const options = [{ text: localize('_Yes_'), value: true }, { text: localize('_No_'), value: false }];
    nextMeetingExist.options = options;
    nextMeetingExist.filterArgs = {
      placeholder: localize('_All_'),
      options: options.map((opt) => ({
        text: opt.text,
        value: opt.value,
        urlValue: `NextMeetingExist eq ${opt.value}`
      }))
    };
    nextMeetingExist.isVisible = false;

    const actionsColumn = new GridColumn('link', localize('_Actions_'), COLUMN_EDITOR_TYPE.LAZY, false);
    actionsColumn.width = isFunction(this._mediator.sendWhatsapp) ? 220 : 170;
    actionsColumn.isVisible = true;
    actionsColumn.freeze = true;
    actionsColumn.customRenderFunc = (entity, cd, column, h) => {
      let actions = [
        h('button', {
          class: `go-to-item-link text--primary icon icon-sms px-1 ms-1`,
          on: {
            click() {
              mediator.sendSms(entity);
            }
          }
        }),
        h('button', {
          class: `go-to-item-link text--primary icon icon-remove px-1 ms-1`,
          on: {
            click() {
              mediator.remove(entity).then(() => {
                self.pullItem(entity);
              });
            }
          }
        }),
        h('button', entity.CustomerId ? {
          class: `go-to-item-link text--primary icon icon-clients-ol px-1 ms-1`,
          on: {
            click() {
              const current = stateManager.currentState('Leads');
              stateManager.setState('Leads', { ...current, visibleIndex: mediator.indexOfItem(entity) }, false, true);
              setTimeout(() => {
                $state.go('CustomerDetails', { customerId: entity.CustomerId });
              });
            }
          }
        } : {
          class: `go-to-item-link text--primary icon icon-add-user px-1 ms-1`,
          on: {
            click() {
              mediator.turnToCustomer(entity);
            }
          }
        }),
        h('button', {
          class: `go-to-item-link text--primary px-1 ms-1 ${localize.isRTL ? 'icon icon-go-right' : 'icon icon-go-left'}`,
          on: {
            click() {
              const current = stateManager.currentState('Leads');
              stateManager.setState('Leads', { ...current, visibleIndex: mediator.indexOfItem(entity) }, false, true);
              setTimeout(() => {
                $state.go('LeadDetails', { 'leadId': entity.LeadId });
              });
            }
          }
        })
      ];

      if (isFunction(this._mediator.sendWhatsapp)) {
        actions.unshift(h('button', {
          class: `go-to-item-link text--primary icon icon-whatsapp px-1 ms-1`,
          on: {
            click() {
              mediator.sendWhatsapp(entity);
            }
          }
        }));
      }

      return h('div', {}, actions);
    }

    this.columns = [
      leadName,
      actionsColumn,
      mobileFirst,
      lastAppeal,
      status,
      subStatus,
      lastHandlingNote,
      employee,
      lastUpdated,
      nextHandlingTime,
      tags,
      arrivalSource,
      nextMeeting,
      nextMeetingExist,
      totalSpent,
      emailAddress,
      remarks,
      category
    ].filter((x) => !!x);

    this._sortByColumn = new SortByColumn(lastAppeal, true);

    mediator.exportToExcel = () => {
      this._api.export()
    };

    mediator.print = () => {
      let dictionary = Object.assign({}, ...this.columns.filter((c) => {
        return c.isVisible
      }).map((column) => ({
        [column.fieldName]: {
          Label: column.label,
          ToStringFormat: column.fieldName === 'Date' ? "dd/MM/yyyy" : null
        }
      })));
      this._api.print(dictionary).then(function (response) {
        printerMediator.print(response);
      })
        .catch(function (err) {
          this._toaster.error(extractError(err))
        });
    };

    this._firstLoadColumnDelegates = delegates;

    if (isNonEmptyObject(mediator.initilizedQuery)) {
      this.filterValues = mediator.initilizedQuery;
    }
  }

  adaptFilterValueIfNeeded(filter) {
    const { filterValue, column } = filter;
    let adaptedFilterValue = filterValue;
    if (MULTI_SELECTION_FIELDS.includes(column.fieldName) && isNonEmptyArray(column.filterArgs.options)) {
      const valuesMap = column.filterArgs.options.reduce((acc, curr) => ({
        ...acc,
        [curr.value]: curr.urlValue
      }), {})
      adaptedFilterValue = isNonEmptyArray(adaptedFilterValue) ? adaptedFilterValue.map((v) => valuesMap[v]) : valuesMap[adaptedFilterValue];
    }

    return adaptedFilterValue;
  }


  getItemId(item) {
    return item.LeadId;
  }

  setSortBy(iColumn) {
    super.setSortBy(iColumn);
    this.initialize();
  }

  adaptFiltersToQuery(filter) {
    let adaptedFiltersToSet = [];

    const adaptedFilters = filter.concat(this._constantFilters || []).map((x) => {
      if (x.column.fieldName === 'LeadName') {
        // return `(indexof(LeadName,'${x.filterValue}') gt -1)`
        return `(indexof(concat(concat(FirstName,' '),LastName),'${x.filterValue}') gt -1 or indexof(concat(concat(LastName,' '),FirstName),'${x.filterValue}') gt -1)`
      }
      if (x.column.fieldName === 'MobileFirst') {
        return `(indexof(MobileFirst,'${x.filterValue}') gt -1)`
      } else if (DATE_RANGE_FIELDS.includes(x.column.fieldName) && x.filterValue.length) {
        return adaptDateRangeQuery(x);
      } else if (x.column.fieldName === 'NextMeetingExist') {
        return `NextMeeting ${x.filterValue ? 'ne null' : 'eq null'}`
      } else if (x.column.fieldName === 'Tags') {
        return `Tags/any(tag:tag/${this.adaptFilterValueIfNeeded(x).join(' or tag/')})`
      } else {
        let adaptedFilterValue = this.adaptFilterValueIfNeeded(x);
        if (isNonEmptyArray(adaptedFilterValue) && adaptedFilterValue.length > 1) {
          return `(${adaptedFilterValue.join(' or ')})`;
        }

        return adaptedFilterValue;
      }
    });

    adaptedFiltersToSet = adaptedFilters;

    this._mediator.notify('filterChange', filter, adaptedFiltersToSet);
    return adaptedFiltersToSet;
  }

  get api() {
    return this._api;
  }

  getMenuActions(item) {
    const goToEntityAction = item.CustomerId ?
      { icon: 'icon icon-clients-ol', text: this._localize('_CustomerDetails_'), value: 'go-to-customer' } :
      { icon: 'icon icon-add-user', text: this._localize('_TransformLeadToCustomer_'), value: 'turn-to-customer' };

    const toReturn = [
      { icon: 'icon icon-sms', text: this._localize('_SendSms_'), value: 'send-sms' },
      { icon: 'icon icon-remove', text: this._localize('_Remove_'), value: 'remove' },
      { icon: 'icon icon-clock', text: this._localize('_Bounce_'), value: 'bounce' },
      goToEntityAction,
      { icon: 'icon icon-go-left', text: this._localize('_LeadDetails_'), value: 'go-to-lead' }
    ];

    if (isFunction(this._mediator.sendWhatsapp)) {
      toReturn.unshift({
        icon: 'icon icon-whatsapp',
        text: this._localize('_SendByWhatsapp_'),
        value: 'send-whatsapp'
      });
    }

    return toReturn;
  }

  notifyMenuItemSelected(item, menuValue) {
    switch (menuValue) {
      case 'send-sms':
        this._mediator.sendSms(item);
        break;
      case 'send-whatsapp':
        this._mediator.sendWhatsapp(item);
        break;
      case 'remove':
        this._mediator.remove(item).then(() => {
          this.pullItem(item);
        });
        break;
      case 'bounce':
        this._mediator.bounce(item).then(() => {
          this.pullItem(item);
        });
        break;
      case 'go-to-customer':
        const current = this._stateManager.currentState('Leads');
        this._stateManager.setState('Leads', { ...current, visibleIndex: this._mediator.indexOfItem(item) }, false, true);
        setTimeout(() => {
          this._$state.go('CustomerDetails', { customerId: item.CustomerId });
        })
        break;
      case 'turn-to-customer':
        const currentS = this._stateManager.currentState('Leads');
        this._stateManager.setState('Leads', { ...currentS, visibleIndex: this._mediator.indexOfItem(item) }, false, true);
        this._mediator.turnToCustomer(item);
        break;
      case 'go-to-lead':
        const currentState = this._stateManager.currentState('Leads');
        this._stateManager.setState('Leads', { ...currentState, visibleIndex: this._mediator.indexOfItem(item) }, false, true);
        setTimeout(() => {
          this._$state.go('LeadDetails', { leadId: item.LeadId });
        })
        break;
    }
  }

  handleDelayedFirstLoad() {
    delete this._firstLoadColumnDelegates;
    delete this._firstLoadPromise;
    const updatedParams = this._firstLoadLatestParams.slice();
    delete this._firstLoadLatestParams;
    return this.loadOnRemote.apply(this, updatedParams)
  }

  loadOnRemote(top, skip, sort, filter) {
    /**
     * We want to make sure the first load is done only after all the column delegates are loaded.
     * Any columns that depend on a prefetched columns must be added to the _columnDelegates array.
     * To prevent escalation, even if any exception is thrown by the column delegates, the first load will be done regardless.
     */
    if (this._firstLoadColumnDelegates?.some((x) => x.$$state?.status === 0)) {
      this._firstLoadLatestParams = [...arguments]
      if (this._firstLoadPromise) {
        return this._firstLoadPromise;
      }

      return this._firstLoadPromise = Promise.all(this._firstLoadColumnDelegates)
        .then(this.handleDelayedFirstLoad.bind(this))
        .catch(this.handleDelayedFirstLoad.bind(this));
    }

    this._api.top(top);
    this._api.skip(skip);
    if (sort) {
      this._api.orderBy(sort.column.sortByField || sort.column.fieldName, sort.desc);
    }

    const adaptedFilters = this.adaptFiltersToQuery(filter);
    this._api.filter(adaptedFilters);

    const newFilter = isNonEmptyObject(filter) ? JSON.stringify(filter) : null
    if (newFilter !== this._stringifiedCurrentFilter) {
      this._stringifiedCurrentFilter = newFilter;
      this._api.getInfo().then((data) => {
        this.setFooter(data.results[0], this._mediator.showIncomes);
      }).catch((e) => {
        this._toaster.error(extractError(e))
      });
    }


    return this._api.get().then((result) => {
      setTimeout(() => {
        performIfDelegate(this._mediator.onLoadSuccess, result);
      });

      return new GridApiResult(result.map((item) => {
        return item;
      }),
        top,
        skip,
        null);
    });
  }
}

class DefaultDatePickerLabels extends DatePickerLabels {
  constructor(localize) {
    super();
    this._inputPlaceholder = localize('_ChooseDate_');
    this._selectADate = localize('_ChooseDate_');
    this._pickAStartTime = localize('_StartTime_');
  }

  get inputPlaceholder() {
    return this._inputPlaceholder;
  }

  get selectADate() {
    return this._selectADate;
  }

  get pickAStartTime() {
    return this._pickAStartTime;
  }
}

function adaptDateRangeQuery(dateFilter) {
  const fromDate = moment(dateFilter.filterValue[0]).format(ODATA_DATE_TIME_FORMAT);
  let toDate;
  if (dateFilter.filterValue[1] && dateFilter.filterValue[1].getDate() != dateFilter.filterValue[0].getDate()) {
    toDate = moment(dateFilter.filterValue[1]).format(ODATA_DATE_TIME_FORMAT);
  } else {
    toDate = moment(dateFilter.filterValue[0]).endOf('day').format(ODATA_DATE_TIME_FORMAT);
  };
  return `(${dateFilter.column.fieldName} ge DateTime'${fromDate}' and ${dateFilter.column.fieldName} le DateTime'${toDate}')`;
}

